body {
  padding: 8px;
}

.bp5-table-container.bp5-table-selection-enabled:focus {
  outline: none;
}

#webpack-dev-server-client-overlay {
  display: none;
}

.header {
  margin-bottom: 16px;
  .right {
    display: flex;
    gap: 8px;
  }
}

.cards-page {
  .pagination-cont {
    margin-top: 16px;
    display: flex;
    justify-content: center;
  }
  .pagination {
  
  }

  .filter {
    width: 100px;
    transition: all 400ms ease-in-out;
    &.focused {
      width: 300px;
    }
  }
  
  .add-button {
    position: absolute;
    right: 16px;
    bottom: 16px;
    border-radius: 10px;
  }
  
  .cards-table {
    width: 100%;
    .number {
      width: 174px;
    }
    .name {
      width: 220px;
    }
    .code {
      width: 60px;
    }


    .actions {
      width: 90px;
      
    }

    td.actions {
      .cont {
        display: flex;
        gap: 8px;
  
      }
    }
  }
  

  .loading {
    margin: 16px 0;
  }
}

.users-page {
  .add-button {
    position: absolute;
    right: 16px;
    bottom: 16px;
    border-radius: 10px;
  }

  .users-table {
    width: 100%;
  }

  .loading {
    margin: 16px 0;
  }
}

.loading {
  display: flex;
  justify-content: center;
}

.dialog {
  .save {
    // width: 100%;
  }

  .creation-info {
    font-size: 10px;
    color: gray;
  }
} 


.copyable {
  cursor: pointer;
  &:hover {
    border-bottom: 1px dashed gray;
  }
}

.bp5-running-text table td, .bp5-running-text table th, table.bp5-html-table td, table.bp5-html-table th {
  padding: 5px !important;
}